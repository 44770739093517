import(/* webpackMode: "eager", webpackExports: ["CountLazy"] */ "/vercel/path0/apps/iffy/app/count-lazy.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/iffy/app/dashboard.png");
;
import(/* webpackMode: "eager", webpackExports: ["IffyImage"] */ "/vercel/path0/apps/iffy/app/iffy-image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/iffy/components/antiwork-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/apps/iffy/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/iffy/node_modules/next/dist/client/image-component.js");
